.altai-theme-admin #adminContentView {
  /*display: flex;*/
  flex: 1;
  overflow-y: scroll;
}
.altai-theme-admin #altai-admin-top {
  background-color: #efefef;
  padding: 19px;
  display: flex;
}
.altai-theme-admin #altai-admin-top .inner {
  display: flex;
  flex: 1;
  max-width: 960px;
}
.altai-theme-admin #altai-admin-top .inner .nav {
  flex: 1;
}
.altai-theme-admin #altai-admin-top .inner .user {
  text-align: right;
}
